import { render, staticRenderFns } from "./GeneralCostsCardView.vue?vue&type=template&id=201dea91&scoped=true&"
import script from "./GeneralCostsCardView.vue?vue&type=script&lang=js&"
export * from "./GeneralCostsCardView.vue?vue&type=script&lang=js&"
import style0 from "./GeneralCostsCardView.vue?vue&type=style&index=0&id=201dea91&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "201dea91",
  null
  
)

export default component.exports